import './App.css';

const App = () => {
  return (
    <div className="App">
      <h1>Har du tukla med det? &#169;</h1>

      <p>Ja: <br />
        <a href="https://www.youtube.com/watch?v=R5bdWFCz6Tw" target="_top">Stakkars deg!</a>
      </p>

      <p>Nei: <br />
        Problemet løst, <a href="https://varsel.it.ntnu.no/" target="_top">varsel.it.ntnu.no</a>
      </p>

      <img src={process.env.PUBLIC_URL + "/varsel.jpg"} alt="katte-meme" />
    </div>
  );
};

export default App;
